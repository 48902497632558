body {
  margin: 0;
  padding: 0;
  max-width: initial;
  overflow-x: initial;
  word-break: initial;
  width: 100%;
}

#root {
  margin: 0 auto;
  padding: 0;
}

main {
  margin: 0 auto;
  max-width: 750px;
  padding: 2rem;
  border-radius: 6px;
  overflow-x: hidden;
  word-break: break-word;
  overflow-wrap: break-word;
  color: var(--nc-tx-2);
  font-size: 1.03rem;
  line-height: 1.5;
}

header {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

img.logo {
  max-height: 500px;
  max-width: 500px;
  margin: auto;
  width: 200px;
  height: 200px;
}

.main-title {
  max-width: 750px;
}

nav {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-direction: column;
}

nav a {
  margin-top: 1rem;
}

@media screen and (min-width: 520px) {
  nav {
    flex-direction: row;
  }

  img.logo {
    width: auto;
    height: auto;
  }
}

/* new.css */
:root {
  --nc-font-sans: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --nc-font-mono: Consolas, monaco, 'Ubuntu Mono', 'Liberation Mono',
    'Courier New', Courier, monospace;
  --nc-tx-1: #000000;
  --nc-tx-2: #1a1a1a;
  --nc-bg-1: #ffffff;
  --nc-bg-2: #f6f8fa;
  --nc-bg-3: #e5e7eb;
  --nc-lk-1: #0070f3;
  --nc-lk-2: #0366d6;
  --nc-lk-tx: #ffffff;
  --nc-ac-1: #79ffe1;
  --nc-ac-tx: #0c4047;
}
@media (prefers-color-scheme: dark) {
  :root {
    --nc-tx-1: #ffffff;
    --nc-tx-2: #eeeeee;
    --nc-bg-1: #000000;
    --nc-bg-2: #111111;
    --nc-bg-3: #222222;
    --nc-lk-1: #3291ff;
    --nc-lk-2: #0070f3;
    --nc-lk-tx: #ffffff;
    --nc-ac-1: #7928ca;
    --nc-ac-tx: #ffffff;
  }
}

button,
html,
input,
select {
  font-family: var(--nc-font-sans);
}
body {
  margin: 0 auto;
  word-break: break-word;
  overflow-wrap: break-word;
  background: var(--nc-bg-3);
  color: var(--nc-tx-2);
  font-size: 1.03rem;
  line-height: 1.5;
}
::selection {
  background: var(--nc-ac-1);
  color: var(--nc-ac-tx);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  color: var(--nc-tx-1);
  padding-top: 0.875rem;
  text-align: center;
}
h1,
h2,
h3 {
  color: var(--nc-tx-1);
  padding-bottom: 2px;
  margin-bottom: 8px;
}
h4,
h5,
h6 {
  margin-bottom: 0.3rem;
}
h1 {
  font-size: 2.25rem;
}
h2 {
  font-size: 1.85rem;
}
h3 {
  font-size: 1.55rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: 0.875rem;
}
a {
  color: var(--nc-lk-1);
}
a:hover {
  color: var(--nc-lk-2);
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.hero-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  max-width: 1200px;
  padding: 1rem;
  box-sizing: border-box;
}

.hero-link {
  flex: 1 1 calc(50% - 0.5rem);
  min-width: 300px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease;
}

.hero-link:hover {
  transform: scale(1.02);
}

.hero-image {
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 12px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.hero-image.season-one {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../../public/rope-pullers-banner.jpg');
}

.hero-image.season-two {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../../public/lairy-shadows-banner.jpg');
}

.hero-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  color: white;
}

.hero-overlay h2 {
  margin: 0 0 0.5rem 0;
  font-size: 2rem;
}

.hero-overlay p {
  margin: 0;
  font-size: 1.1rem;
  opacity: 0.9;
}

@media (max-width: 768px) {
  .hero-link {
    flex: 1 1 100%;
  }
  
  .hero-image {
    height: 250px;
  }
  
  .hero-overlay h2 {
    font-size: 1.75rem;
  }
}

.social-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin: 2rem 0;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  text-decoration: none;
  color: inherit;
  background: var(--nc-bg-2);
  transition: all 0.2s ease;
}

.nav-link:hover {
  background: var(--nc-bg-3);
  transform: translateY(-2px);
}

.nav-link svg {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .social-nav {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }
  
  .nav-link {
    justify-content: center;
  }
}

.intro-section {
  background: var(--nc-bg-2);
  border-radius: 16px;
  padding: 2rem;
  margin: 2rem auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  max-width: 800px;
}

.intro-text {
  font-size: 1.2rem;
  line-height: 1.7;
  margin: 0;
  color: var(--nc-tx-2);
}

@media (max-width: 768px) {
  .intro-section {
    padding: 1.5rem;
    margin: 1rem auto;
  }
  
  .intro-text {
    font-size: 1.1rem;
  }
}